<template>
  <div :class="isCustomizeMobile ? 'container mt-2 p-2' : 'mx-2'">
    <div class="d-flex align-items-center">
      <inline-svg src="/text-primary.svg" class="mr-1" width="24px" height="24px" />
      <div class="title">Text</div>
    </div>

    <template v-for="(tOption, index) in textOptions" :key="tOption.abbr">
      <div
        v-if="tOption.abbr !== 'quote' || !isEmpty(get(character, 'info.quote'))"
        class="sub-title"
        :class="{ 'mt-2': !index }"
      >
        {{ tOption.name }}
      </div>
      <div
        v-if="tOption.abbr !== 'quote' || !isEmpty(get(character, 'info.quote'))"
        class="d-flex justify-content-between mt-1"
        :class="index === textOptions.length - 1 && isEmpty(get(character, 'info.quote')) ? 'mb-1' : 'mb-3'"
      >
        <ion-select
          mode="md"
          class="font-select mr-2 w-100"
          :value="getFontStyle(tOption.abbr)"
          @ionChange="($event: any) => onFontChange($event.detail.value, tOption.abbr)"
        >
          <ion-select-option
            v-for="(option, index) of options"
            :key="index"
            :value="option.name"
            :class="`font-selector-${option.key}`"
            >{{ option.name }}
            <div v-if="option.name === 'Roboto'">(default)</div></ion-select-option
          >
        </ion-select>

        <div>
          <div class="d-flex mx-2">
            <div
              class="clickable-item"
              :class="isOptionBold[tOption.abbr] ? 'bold-checked' : 'unchecked-bold'"
              @click="boldChecked(tOption.abbr)"
            >
              <div class="set-bold-margin d-flex justify-content-center">B</div>
            </div>
            <div
              class="mx-1 clickable-item"
              :class="isOptionItalic[tOption.abbr] ? 'bold-checked' : 'unchecked-bold'"
              @click="italicChecked(tOption.abbr)"
            >
              <div class="set-italic-margin d-flex justify-content-center">𝐼</div>
            </div>
          </div>
        </div>
        <div>
          <color-circle-box
            :value="getSelectedColor(tOption.abbr)"
            :is-background="false"
            :is-customize="isCustomizeMobile"
            @changed="(color) => colorChanged(color, tOption.abbr)"
            @select="actionClicked"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import ColorCircleBox from '@/shared/components/color-customize-box.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import constants from '@/shared/statics/constants';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';

const { isCustomizationSize } = useWindowSize();
const { currentTemplate, changeThemeTextKey, template } = characterStylizeStore();
const { character } = characterProfileStore();
const props = defineProps({
  isCustomizeMobile: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
    default: '22px',
  },
  textOptions: {
    type: Array,
    default: () => [
      { abbr: 'charName', name: 'Character Name' },
      { abbr: 'quote', name: 'Quote' },
      { abbr: 'titles', name: 'Titles' },
      { abbr: 'default', name: 'Default Fonts' },
    ],
  },
});
const ready = ref(true);
const emits = defineEmits(['click']);
const options = constants.customizeFonts;

const isFirstLoad = ref(true);

const onFontChange = (value: string, abbr: string) => {
  scrollTo(abbr);
  mutate(abbr, 'font', value);
};

const textOptions = toRef(props, 'textOptions') as any;

const isOptionBold: any = ref({
  charName: true,
  quote: true,
  titles: true,
  default: false,
});

const isOptionItalic: any = ref({
  charName: false,
  quote: true,
  titles: false,
  default: false,
});

const getFontStyle = (abbr: string) => {
  return isEmpty(get(currentTemplate.value, 'template_applied')) &&
    isEmpty(get(currentTemplate.value, `data.theme.text.${abbr}.font`))
    ? 'Roboto'
    : get(currentTemplate.value, `data.theme.text.${abbr}.font`, 'Roboto');
};

const getSelectedColor = (abbr: string) => {
  return get(currentTemplate.value, `data.theme.text.${abbr}.color`);
};

const mutate = (mainKey: any, key: any, value: any) => {
  changeThemeTextKey(mainKey, key, value);
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};

const boldChecked = (abbr: any) => {
  isOptionBold.value[abbr] = !isOptionBold.value[abbr];
  mutate(abbr, 'bold', isOptionBold.value[abbr]);
  scrollTo(abbr);
};

const italicChecked = (abbr: any) => {
  isOptionItalic.value[abbr] = !isOptionItalic.value[abbr];
  mutate(abbr, 'italics', isOptionItalic.value[abbr]);
  scrollTo(abbr);
};
const colorChanged = (newColor: string, abbr: any) => {
  mutate(abbr, 'color', newColor);
  scrollTo(abbr, 'center');
};

const scrollTo = (id: string, block: ScrollLogicalPosition = 'nearest') => {
  if (!isCustomizationSize.value)
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block });
};

const actionClicked = (select: any) => {
  emits('click', select);
};

watch(
  currentTemplate,
  async (value: any) => {
    if (!isEmpty(value) && isFirstLoad.value) {
      isFirstLoad.value = false;
      Object.keys(isOptionBold.value).forEach((key: any) => {
        isOptionBold.value[key] = get(value, `data.theme.text.${key}.bold`, true);
      });
      Object.keys(isOptionItalic.value).forEach((key: any) => {
        isOptionItalic.value[key] = get(value, `data.theme.text.${key}.italics`, false);
      });
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="sass" scoped>
.container
  border: 2px solid #eaecf6
  border-radius: 10px
.set-bold-margin
  line-height: 20px
.set-italic-margin
  display: inline-block
  width: 100%
  line-height: 20px
  position: relative

.bold-checked
  font-weight: bold
  background: #214163
  border: 3px solid #214163
  color: white
  height: 24px
  width: 24px
  border-radius: 6px

.unchecked-bold
  font-weight: bold
  border-radius: 6px
  border: 3px solid #eaecf6
  background: none
  color: #214163
  height: 24px
  width: 24px
.sub-title
  color: #214163
  font-size: 16px
  margin-top: -15px
  font-weight: bold
.dark .sub-title
  color: #000000 !important
.title
  color: #214163
  font-size: v-bind(fontSize)
  font-weight: bold
.dark .title
  color: #000000 !important

.font-select
  height: 28px !important
  margin-top: -2px !important
  background-color: #f9f9ff !important
  border: 1px solid #eaecf6
  font-size: 14px !important
  padding: 0 10px !important
  min-height: unset !important
  max-width: unset
  border-radius: 0.35rem !important
  ::v-deep .select-wrapper
    &.native-wrapper
      overflow: unset !important
  @media(min-width:1460px)
    min-width: 140px !important
    width: 109px !important
    overflow: hidden
    text-overflow: ellipsis
.dark .font-select
  color: black !important
  ::v-deep .select-wrapper
    &.native-wrapper
      color: black !important
</style>
