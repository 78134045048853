<template>
  <div :class="{ 'mx-2': showStyle }">
    <div class="d-flex align-items-center" v-if="showStyle">
      <inline-svg src="/theme-style-icon.svg" class="mr-1" width="24px" height="24px" />
      <div class="title">Styles:</div>
    </div>
    <div v-for="section in sections" :key="section.name" class="p-2 mt-1 container">
      <div class="d-flex align-items-center">
        <!-- <ion-icon class="icon" :icon="section.icon" /> -->
        <inline-svg
          :src="section.icon"
          height="30"
          width="30"
          class="mr-1 icon"
          :class="{ 'prf-icon': get(section, 'name') === 'profilePic' }"
        />
        <div class="sub-title mx-1">{{ section.title }}:</div>
      </div>
      <div>
        <div class="text mt-1">Border:</div>
        <div class="temp-row">
          <div
            v-for="border in section.borders"
            :key="border.value"
            class="small-button w-100 clickable-item"
            :class="getBorderClass(section.name, border.value)"
            :style="border.style"
            @click="changedStyles(section.name, 'border', border.value)"
          >
            <div class="text-center d-flex justify-content-center">{{ border.label }}</div>
          </div>
        </div>
        <div class="d-flex align-items-center my-3">
          <div class="text" style="margin-top: -2px">Border Color:</div>
          <div class="ml-auto w-50">
            <color-circle-box
              :value="getColor(section.name)"
              :is-background="true"
              @changed="(color) => changedStyles(section.name, 'color', color)"
              @select="actionClicked"
            />
          </div>
        </div>
        <div class="text" style="margin-top: -15px">Shadow:</div>
        <div class="temp-row">
          <div
            v-for="shadow in section.shadows"
            :key="shadow.value"
            class="small-button w-100 clickable-item"
            :class="getShadowClass(section.name, shadow.value)"
            @click="changedStyles(section.name, 'shadow', shadow.value)"
          >
            {{ shadow.label }}
          </div>
        </div>
        <div v-if="section.edges" class="text mt-2">Edges:</div>
        <div v-if="section.edges" class="temp-row">
          <div
            v-for="edge in section.edges"
            :key="edge.value"
            class="small-button w-100 clickable-item"
            :class="getEdgeClass(section.name, edge.value)"
            :style="{ borderRadius: edge.radius }"
            @click="selectedEdge(section.name, edge.value)"
          >
            <div class="text-center d-flex justify-content-center">{{ edge.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { personCircle, scaleOutline, readerOutline } from 'ionicons/icons';
import ColorCircleBox from '@/shared/components/color-customize-box.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';

const { isCustomizationSize } = useWindowSize();
const { currentTemplate, changeStylesKey } = characterStylizeStore();
const emits = defineEmits(['click']);

const props = defineProps({
  sections: {
    type: Array,
    default: [
      {
        name: 'profilePic',
        icon: '/person-circle-outline.svg',
        title: 'Profile Pic',
        borders: [
          { label: 'None', value: 'none', style: {} },
          { label: 'Solid', value: 'solid', style: { borderStyle: 'solid', borderColor: '#eaecf6' } },
          { label: 'Dotted', value: 'dotted', style: { borderStyle: 'dotted', borderColor: '#7a7e9b' } },
          {
            label: 'Dashed',
            value: 'dashed',
            style: { borderStyle: 'dashed', borderColor: '#7a7e9b', marginRight: '4px' },
          },
        ],
        shadows: [
          { label: 'None', value: 'none' },
          { label: 'A little', value: 'alittle' },
          { label: 'A lot', value: 'alot' },
        ],
      },
      {
        name: 'topsection',
        icon: '/top-section-icon.svg',
        title: 'Top Section',
        borders: [
          { label: 'None', value: 'none', style: {} },
          { label: 'Solid', value: 'solid', style: {} },
          { label: 'Dotted', value: 'dotted', style: { borderStyle: 'dotted' } },
          { label: 'Dashed', value: 'dashed', style: { borderStyle: 'dashed', marginRight: '4px' } },
        ],
        shadows: [
          { label: 'None', value: 'none' },
          { label: 'A little', value: 'alittle' },
          { label: 'A lot', value: 'alot' },
        ],
        edges: [
          { label: 'Sharp', value: 'sharp', radius: '0px' },
          { label: 'Round', value: 'round', radius: '6px' },
          { label: 'Very Round', value: 'veryround', radius: '20px' },
        ],
      },
      {
        name: 'infoSections',
        icon: '/info-icon.svg',
        title: 'Info Sections',
        borders: [
          { label: 'None', value: 'none', style: {} },
          { label: 'Solid', value: 'solid', style: {} },
          { label: 'Dotted', value: 'dotted', style: { borderStyle: 'dotted' } },
          { label: 'Dashed', value: 'dashed', style: { borderStyle: 'dashed', marginRight: '4px' } },
        ],
        shadows: [
          { label: 'None', value: 'none' },
          { label: 'A little', value: 'alittle' },
          { label: 'A lot', value: 'alot' },
        ],
        edges: [
          { label: 'Sharp', value: 'sharp', radius: '0px' },
          { label: 'Round', value: 'round', radius: '6px' },
          { label: 'Very Round', value: 'veryround', radius: '20px' },
        ],
      },
    ],
  },
  showStyle: {
    type: Boolean,
    default: false,
  },
});
const sections = toRef(props, 'sections') as any;
const getBorderClass = (sectionName: string, value: string) => {
  const border = get(currentTemplate.value, `data.theme.styles.${sectionName}.border`);
  return border === value ? 'selected-border' : 'set-border';
};

const getShadowClass = (sectionName: string, value: string) => {
  const shadow = get(currentTemplate.value, `data.theme.styles.${sectionName}.shadow`);
  return shadow === value ? 'selected-shadow' : 'set-shadow';
};
const getEdgeClass = (sectionName: string, value: string) => {
  const edge =
    (sectionName === 'infoSections' && get(currentTemplate.value, 'data.theme.styles.sectionEdges')) ||
    get(currentTemplate.value, `data.theme.styles.${sectionName}.edges`);
  return edge === value ? 'selected-edges' : 'set-edges';
};

const getColor = (sectionName: string) => {
  return get(currentTemplate.value, `data.theme.styles.${sectionName}.color`);
};

const changedStyles = (name: string, selectedStyle: string, value: string) => {
  changeStylesKey(name, selectedStyle, value);
  if (!isCustomizationSize.value) {
    name === 'topsection' || name === 'profilePic'
      ? document
          .getElementById('topSection')
          ?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
      : name === 'infoSections'
      ? document
          .getElementById('infoSection')
          ?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
      : document.getElementById(name)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  }
};

const actionClicked = (select: any) => {
  emits('click', select);
};

const selectedEdge = (sectionName: string, value: string) => {
  sectionName === 'infoSections'
    ? changeStylesKey('sectionEdges', '', value)
    : changeStylesKey(sectionName, 'edges', value);
};
</script>

<style lang="sass" scoped>
.prf-icon
  fill: #214163
.container
  background: white
  border: 2px solid #eaecf6
  border-radius: 10px
.set-edges
  background: rgb(234, 236, 246)

.dark .set-edges
  color: #000000 !important
.selected-edges
  background: #214163
  color: white

.set-shadow
  background: #f9f9ff
  border-radius: 6px
  border: 1px solid #eaecf6 !important
.dark .set-shadow
  color: #000000 !important

.selected-shadow
  background: #214163
  color: white
  border-radius: 6px

.set-border
  background: #f9f9ff
  border-radius: 6px
.dark .set-border
  color: #000000 !important
.selected-border
  background: #214163
  color: white
  border-radius: 6px
.dark .selected-border
  color: white !important

.title
  color: #214163
  font-size: 24px
  font-weight: bold
.dark .title
  color: #000000 !important

.sub-title
  color: #214163
  font-size: 18px
  font-weight: bold
.dark .sub-title
  color: #000000 !important
.text
  color: #214163
  font-size: 14px
  font-weight: 600

.temp-row
  display: flex
  column-gap: 3px
  justify-content: space-between
  width: 100%
  &:first-child
    margin-left: 1px !important
.small-button
  display: flex
  align-items: center
  justify-content: center
  width: 33%
  height: 28px
  font-size: 13px
  border: 2px solid #eaecf6
  justify-content: center
  display: flex
  align-items: center
  border: none
.middle-btn
  min-width: 33%
  width: 33%
.border-middle-btn
  min-width: 23%
  width: 23%
.border-last-btn
  min-width: 25%
  width: 25%
.last-btn
  width: 38% !important
  min-width: 38%
</style>
