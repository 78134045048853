<template>
  <WelcomeThemeNote class="mx-2" />
  <br />
  <div class="character-customize-key-info position-relative mx-2 pt-2">
    <theme-customize-background @click="actionClicked" />
  </div>
  <div class="character-customize-key-info position-relative m-2 pt-2 pb-1">
    <theme-text @click="actionClicked" />
  </div>
  <div class="character-customize-key-info position-relative m-2 pt-2 pb-3">
    <theme-styles :show-style="true" />
  </div>
</template>
<script lang="ts" setup>
import ThemeCustomizeBackground from './theme-customize-background.vue';
import ThemeText from './theme-text-section.vue';
import ThemeStyles from './theme-styles.vue';
import WelcomeThemeNote from './WelcomeThemeNote.vue';

const windowWidth = ref(window.innerWidth);
const emits = defineEmits(['click']);

const actionClicked = (select: any) => {
  emits('click', select);
};
onMounted(async () => {
  window.addEventListener('resize', handleResize);
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};
</script>

<style scoped lang="sass">

.character-customize-key-info
  background: white
  border-radius: 10px
  @media(max-width:1460px)
    margin-bottom: 15px
</style>
